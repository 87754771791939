import React from 'react'

const ShowProducts = () => {
  return (
    <>
      <h1>All Products are shown here</h1>

    </>
  )
}

export default ShowProducts