import React from 'react'
import Routess from './Routes/Routes'

const App = () => {
  return (
    <>

      <Routess/>

    </>
  )
}

export default App